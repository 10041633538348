import { store } from '@/store'
import { createHash, getGlobalUserType, getStorageUserType } from '@/utils/util'
import _ from 'lodash'
import Papa from 'papaparse'
import { formDataTrans, requestToken } from '../../../api/manage'

export async function getRanterTreeData(userType: number) {
  // 取得根组织数据 get all the Root enterprise data(with the same parentID as the user log in)
  // get real data
  let response: any
  switch (userType) {
    case 0:
      response = await getORGTreeBySuper()
      break
    case 1:
      response = await getORGTreeByORG()
      break
    case 2:
      response = await getORGTreeByOPT()
      break
    default:
      break
  }
  const hash = {}
  createHash(response, hash)
  store.commit('setEnterpriseData', response)
  store.commit('setEnterpriseHashData', hash)

  return store.state.main.enterprise.enterpriseData
}

export async function updateInfo(info: any, userType: number) {
  const id = info.id
  info = _.omit(info, 'id')
  return requestToken({
    url: `/api/v1/organizations/${getGlobalUserType(userType)}/updateOrg/${id}`,
    method: 'put',
    data: info
  })
}
export async function csvFileOut(doc: any, listFile: any) {
  // console.log(listFile)
  const csv = Papa.unparse(listFile)
  // 定义文件内容，类型必须为Blob 否则createObjectURL会报错
  const content = new Blob([csv])
  // 生成url对象
  const urlObject: any = window.URL || window.webkitURL || window
  const url = urlObject.createObjectURL(content)
  // 生成<a></a>DOM元素
  const el = document.createElement('a')
  // 链接赋值
  el.href = url
  el.download = window['vm'].$i18n.tc('csv_export_filename') + '_enterprise.' + doc
  // 必须点击否则不会下载
  el.click()
  // 移除链接释放资源
  urlObject.revokeObjectURL(url)
}
export async function orgCsvFileUpload(uploadFile: any, uploadSeq: any , orgId: any) {
  const formData = formDataTrans({ upload_file : uploadFile, upload_seq : uploadSeq, org_id : orgId })
  const response: any = await requestToken({
    headers: {
      'Content-Type': 'multipart/form-data;',
    },
    url: `/api/v1/organizations/uploadCsv`,
    method: 'post',
    data: formData,
  })
  store.state.main.messagePro = response
}

export async function orgFileUpload(uploadFile: any, uploadSeq: any , orgId: any) {
  const formData = formDataTrans({ upload_file : uploadFile, upload_seq : uploadSeq, org_id : orgId })
  const response: any = await requestToken({
    headers: {
      'Content-Type': 'multipart/form-data;',
    },
    url: `/api/v1/organizations/uploadExcelFile`,
    method: 'post',
    data: formData,
  })
  store.state.main.messagePro = response
}

export async function addDataOfExcelOrg(orgId: any, filePath: any) {
  const response: any = await requestToken({
    url: `/api/v1/organizations/addDataOfExcel/${orgId}`,
    method: 'post',
    data: {
      excel_file: filePath
    },
    timeout: 20000
  })
  store.state.main.messagePro = response
}

/**
 * 删除xuan
 * @param rowIDSelect
 */
export function deleteOne(rowIDSelect: number) {
  return requestToken({
    url: `/api/v1/organizations/${ getStorageUserType() }/${ rowIDSelect }`,
    method: 'delete',
  })
}

/**
 * 解散组织
 * @param rowIDSelect
 */
export function disbandOrg(rowIDSelect: number) {
  return requestToken({
    url: `/api/v1/organizations/disbandOrg/${ rowIDSelect }`,
    method: 'put',
  })
}

export function deleteAll(userType: number, rowIDSelect: number[]) {
  return requestToken({
    url: `/api/v1/organizations/multiDel`,
    method: 'delete',
    data: rowIDSelect,
  })
}
export async function addNewGroup(userType: number, GroupData: any) {
  let response: any
  response = await requestToken({
    // headers: {
    //   'Content-Type': 'multipart/form-data;',
    // },
    url: `/api/v1/organizations/${getGlobalUserType(userType)}`,
    method: 'post',
    data: GroupData,
  })
  store.state.main.enterprise.returnEData = response
  return response
}
// 上传用户头像
export async function uploadImage(GroupData: any) {
  const response: any = await requestToken({
    headers: {
      'Content-Type': 'multipart/form-data;',
    },
    url: `/api/v1/organizations/uploadImage`,
    method: 'post',
    data: formDataTrans(GroupData),
  })
  store.state.main.messagePro = response
}
// 取得用户头像
export async function getImageByORG(orgId: any, filePath: any) {
  const response: any = await requestToken({
    headers: {
      Accept: 'application/json',
    },
    url: '/api/v1/organizations/getFile/' + orgId + '/' + filePath,
    method: 'get',
    responseType: 'blob'
  })
  store.state.main.messagePro = response
}
export function multBindPerson(userType: number, orgId: any, personIds: any) {
  requestToken({
    url: `/api/v1/organizations/${getGlobalUserType(userType)}/multiOwnOrgPersons/${orgId}`,
    method: 'put',
    data: { person_ids: personIds },
  })
}

export function multDeviceBind(userType: number, orgId: any, deviceIds: any) {
  requestToken({
    url: `/api/v1/organizations/${getGlobalUserType(userType)}/multiOwnOrgDevices/${orgId}`,
    method: 'put',
    data: { device_ids : deviceIds },
  })
}

export async function getTreePersonData(userType: number) {
  const response: any = await requestToken({
    url: `/api/v1/organizations/${getGlobalUserType(userType)}/treePersonData`,
    method: 'get'
  })
  store.state.main.messagePro = response
  return response
}

// Organizations树结构信息获取，返回数组（管理员）
export function getORGTreeBySuper() {
  return requestToken({
    url: '/api/v1/organizations/superuser/treeData',
    method: 'get',
  })
}

// Organizations树结构信息获取，返回数组（企业）
export function getORGTreeByORG() {
  return requestToken({
    url: '/api/v1/organizations/manager/treeData',
    method: 'get',
  })
}
// Organizations树结构信息获取，返回数组（操作员）
export function getORGTreeByOPT() {
  return requestToken({
    url: '/api/v1/organizations/operator/treeData ',
    method: 'get',
  })
}

// Organizations信息获取，返回数组（管理员）
export function getORGBySuper() {
  return requestToken({
    url: '/api/v1/organizations/superuser',
    method: 'get',
  })
}
// Organizations信息获取，返回数组（企业）
export function getORGByORG() {
  return requestToken({
    url: '/api/v1/organizations/manager',
    method: 'get',
  })
}
// Organizations信息获取，返回数组（操作员）
export function getORGByOPT() {
  return requestToken({
    url: '/api/v1/organizations/operator',
    method: 'get',
  })
}

async function getEnterpriseDataByUserType(userType, transParams) {
  return requestToken({
    url: `/api/v1/organizations/${getGlobalUserType(userType)}`,
    method: 'get',
    params: transParams,
  })
}

export function getOrgInfo(orgId) {
  return requestToken({
    url: `/api/v1/organizations/${getStorageUserType()}/getAttributesNum/${orgId}`,
    method: 'get',
    params: orgId
  })
}

// 下载组织的csv文件模版
export async function downloadOrgTempCsv() {
  const response =  requestToken({
  headers: {
    Accept: 'application/json',
  },
  url: `/api/v1/organizations/downloadCsvTemplate`,
  method: 'get',
  responseType: 'blob'
  })

  store.state.main.messagePro = response
  return response
}

/**
 * 更新多组织
 * @param orgId
 * @returns
 */
export function updateMultiOrg(orgId, orgData) {
  return requestToken({
    url: `/api/v1/organizations/multiUpdateOrg/${orgId}`,
    method: 'put',
    data: orgData
  })
}

// 获得这个组织的直属下级个数，人员个数，设备个数
export function getDirectlyAttrNum(orgId: any) {
  return requestToken({
    url: `/api/v1/organizations/getDirectlyAttrNum/${orgId}`,
    method: 'get'
  })
}


// 删除或者移到组织
export function delOrg(orgId: any, moveId: any) {
  return requestToken({
    url: `/api/v1/organizations/delOrg/${orgId}?moved_id=${moveId}`,
    method: 'delete'
  })
}

// 企业特殊配置
export async function getFeatureTOrganizationCode(orgId: any) {
  const response: any = await requestToken({
    url: `/api/v1/utils/getFeatureTOrganizationCode/${orgId}`,
    method: 'get'
  })
  const showOrgCode: any = response.t_organization_code === 'eps'
  store.commit('setEnterpriseFeatureData', response)
  store.commit('showOrgCode', showOrgCode)
  sessionStorage.setItem('showOrgCode', showOrgCode)
  return store.state.main.enterprise.featureData
}
